import React from 'react'
import Layout from "../components/layout"


export default () => {
  return (
    <Layout
          title="About"
          description="About our Sample and Approximate Entropy Calculator" >

          <h4>Algorithms used in this project</h4>
          <p>We use the algorithms mentioned in <a href="https://www.mdpi.com/1099-4300/21/6/541" rel="noopener noreferrer" target="_blank">this</a> paper.</p>


          <hr />

          <h4>The structure of .CSV file</h4>
          <p>The file must be in <strong>.csv</strong> format (not .excel) and contain two columns:</p>
          <p><strong>time</strong>(col 1) and <strong>data</strong>(col 2), with the first row used as header.</p>
          <p>Here's an example: <a href="/sample.csv">sample.csv</a></p>


          <hr/>


          <h4>Collaborators</h4>
          <ul>
            <li>Dr. Behrouz Far</li>
            <li>Dr. Alireza Bahramian</li>
            <li>Dr. Hossein Hejazi</li>
            <li>Masoud Karimi Fatemi (M.Sc.)</li>
          </ul>
    </Layout>
    )


}
